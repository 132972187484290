<template>
  <Popup
    v-show="show_popup_etre_rappele"
    @close="show_popup_etre_rappele = false"
    padding_popup="p-0"
    max_width_model=" w-[1050px]"
  >
    <template #body>
      <div class="flex">
        <div
          class="flex bg-promy-blue-270 w-72 pt-10 rounded-tl rounded-bl justify-center lg:hidden"
        >
          <div class="flex flex-col items-start">
            <div class="flex items-center mx-auto">
              <LogoIcon classes=" w-12 h-12" />
              <p class="font-bold text-2xl font-main ml-3 text-white">Promy</p>
            </div>
            <div class="flex flex-1 p-12">
              <img
                :src="`/images/etreRappele/${message_selected.image}.svg`"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="flex flex-col flex-1">
          <div class="flex flex-col pl-11 pr-16 py-8">
            <p
              class="text-promy-green-350 text-3xl mb-6 font-semibold sm:text-xl"
            >
              {{ message_selected.message }}
            </p>
            <p>
              Laissez-nous vos coordonnées afin qu’un expert prenne contact avec
              vous au plus vite
            </p>
          </div>
          <div class="flex flex-col px-11 bg-promy-gray-50 py-5 rounded-br">
            <div class="container-form grid">
              <div
                class="flex flex-col space-y-4 flex-1 pr-9 border-r-2 g-form sm:border-r-0"
              >
                <div class="flex flex-col py-4 space-y-2">
                  <pro-input
                    class="md:col-span-2 sm:text-sm"
                    label="Prénom et nom "
                    placeholder=" "
                    :rules="'required'"
                    v-model="form.nom_prenom"
                    type="text"
                  />
                  <pro-input-tel
                    class="md:col-span-2 sm:text-sm"
                    label="Mobile"
                    :rules="'required'"
                    v-model="form.telephone_mobile"
                    @is_valid="checkTelValidation"
                  />
                </div>
              </div>
              <div
                class="flex flex-col pl-9 sm:pl-0 justify-center w-[320px] space-y-2 g-green-number"
              >
                <p>Ou appelez gratuitement le :</p>
                <Contact
                  :with_contact_btn="false"
                  class="h-fit scale-75 mr-auto origin-left"
                />
                <p class="text-promy-gray-250 text-sm">
                  Nos conseillers sont disponibles du lundi au vendredi de 9h30
                  à 18h30
                </p>
              </div>
              <pro-button
                @click="!is_loading && etreRappele()"
                class="g-btn-form mt-2 sm:mt-0 mb-4 sm:my-2 w-fit px-[4rem] flex justify-center font-semibold py-[0.8rem] border-promy-green-350 rounded-lg"
                :disabled="is_tel_valid ? false : true"
              >
                Être rappelé
              </pro-button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Popup>
</template>

<script>
import { mapGetters } from 'vuex'
import parcelle from '@/mixins/parcelle'
import batiment from '@/mixins/batiment'
import helpers from '@/mixins/helpers'
export default {
  mixins: [parcelle, batiment, helpers],
  data() {
    return {
      form: {
        nom_prenom: null,
        telephone_mobile: null,
        label: null,
        lat: null,
        lon: null,
        code_postal: null,
        code_insee: null,
        ville: null,
        id_parcelle: null,
        surface_parcelle: null,
        surface_batiments: null,
        g_p_adress_id: null,
        parcelles: [],
        batiments: [],
        zones_urbanisme: [],
      },
      random_messages: [
        {
          message: 'Promy a détecté un potentiel sur votre parcelle',
          image: 'icon1',
        },
      ],
      message_selected: {
        message: '',
        image: '',
      },
      is_tel_valid: false,
      is_loading: false,
    }
  },
  computed: {
    ...mapGetters({
      current_parcelle: 'parcelle/current_parcelle',
      address: 'address/address',
      isLoggedIn: 'auth/isLoggedIn',
      current_batiments: 'parcelle/current_batiments',
      user: 'auth/user',
      infos_urbanisme: 'address/infos_urbanisme',
    }),
    show_popup_etre_rappele: {
      get() {
        return this.$store.getters['address/show_popup_etre_rappele']
      },
      set(val) {
        this.$store.commit('address/SET_SHOW_POPUP_ETRE_RAPPELE', val)
      },
    },
  },
  created() {
    if (this.user) {
      this.form.telephone_mobile = this.user.telephone_mobile
        ? this.user.telephone_mobile
        : null
      this.form.nom_prenom =
        this.user.prenom && this.user.nom
          ? this.user.prenom + ' ' + this.user.nom
          : null
    }
  },
  watch: {
    show_popup_etre_rappele(is_show) {
      if (is_show && this.address.is_belongs_to_selected_departments) {
        this.message_selected =
          this.random_messages[
            Math.floor(Math.random() * this.random_messages.length)
          ]
      } else {
        this.message_selected = {
          message:
            'Promy n’a pas su détecter un potentiel – pouvez-vous nous donner plus d’informations sur votre parcelle ?',
          image: 'icon1',
        }
      }
    },
  },
  methods: {
    etreRappele() {
      if (!this.form.nom_prenom || !this.form.telephone_mobile) {
        this.toast('', 'Veuillez remplir tous les champs', 'warning')
        return
      }
      this.is_loading = true
      let coordinates = this.address.information.geometry.coordinates
      let properties = this.address.information.properties

      let zones = {
        features: this.infos_urbanisme.data.urbanisme_parcelles
          .map((item) => item?.zone_urba?.features)
          .filter((el) => el)
          .flat(),
        type: 'FeatureCollection',
      }

      let zone_urba_obj = this.removeDuplicateFeature(zones.features)

      zone_urba_obj.map((zone) => {
        zone.properties = this.convertAttributesToLowerCase(zone.properties)
      })
      this.form.label = properties.label
      this.form.code_postal = properties.postcode
      this.form.code_insee = properties.citycode
      this.form.ville = properties.city
      this.form.lon = coordinates[0]
      this.form.lat = coordinates[1]
      this.form.id_parcelle = this.current_parcelle.properties.id
      this.form.parcelles = this.own_parcelles
      this.form.batiments = this.current_batiments
      this.form.surface_parcelle = Math.round(
        this.SumSurfaceCadastrale(this.own_parcelles),
      )
      this.form.surface_batiments = Math.round(
        this.totalSurfaceSolBatis(this.current_batiments),
      )
      this.form.g_p_adress_id = this.isLoggedIn ? this.address.id : null
      this.form.zones_urbanisme = this.isExist(zone_urba_obj)
        ? zone_urba_obj
        : []
      this.$http
        .post(
          `/grand-publics/${this.isLoggedIn ? 'alert-user' : 'alert-guest'}`,
          this.form,
        )
        .then((response) => {
          this.toast('', response.data.message, 'success')
          this.$store.commit('address/SET_SHOW_POPUP_ETRE_RAPPELE', false)
          this.is_loading = false
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.toast('', 'Veuillez remplir tous les champs', 'warning')
            this.is_loading = false
          }
        })
    },
    checkTelValidation(is_valid) {
      this.is_tel_valid = is_valid
    },
  },
}
</script>
<style lang="scss" scoped>
.g-form {
  grid-area: g-form;
}

.g-green-number {
  grid-area: g-green-number;
}

.g-btn-form {
  grid-area: g-btn-form;
}

.container-form {
  row-gap: 20px;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'g-form g-green-number'
    'g-btn-form .';
}

@screen sm {
  .container-form {
    row-gap: 20px;
    grid-template-columns: 1fr;
    grid-template-areas:
      'g-form'
      'g-btn-form'
      'g-green-number';
  }
}
</style>
